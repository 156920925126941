@import '../../styles/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  .col {
    width: 100%;
  }
  @media only screen and (min-width: $sm-screen) {
    flex-direction: row;
    justify-content: center;
    .col {
      width: 50%;
      border-radius: $radius;
    }
  }
  .mail {
    text-align: center;
  }
  .error {
    text-align: center;
    font-size: 13px;
    color: $danger;
  }
  .message {
    text-align: center;
    font-size: 13px;
    color: $secondary-color;
  }
  .form {
    position: relative;
    margin: 50px 0px 0px 0px;
    padding: $margin-md;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (min-width: $sm-screen) {
      background-color: $white;
      justify-content: center;
      margin: $margin-md 0px $margin-md $margin-md;
    }
    .signin {
      margin-top: 10px;
      min-width: 240px;
      width: 240px;
      .form-group {
        @include form-group;
      }
    }
    .forgot {
      display: block;
      width: 100%;
      text-align: center;
      color: $primary-color;
      font-size: 12px;
    }
    .submit {
      margin: $margin-md auto;
      width: 100%;
      @include btn-primary;
      p {
        margin: 0px;
        padding-left: 10px;
      }
      &.password {
        width: 120px;
        p {
          padding-left: 0px;
        }
      }
    }
    .illu-mobile {
      display: block;
      width: 100%;
      margin: 20px 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
      }
      @media only screen and (min-width: $sm-screen) {
        display: none;
      }
    }
    .legal {
      @include legal;
      color: rgba($dark, 0.6);
      a {
        color: rgba($dark, 0.6);
      }
      @media only screen and (min-width: $sm-screen) {
        width: 100%;
        position: absolute;
        bottom: 0px;
        padding: 0px $margin-md;
      }
    }
    @media only screen and (min-width: $sm-screen) {
      justify-content: center;
    }
  }
  .illu {
    display: none;
    @media only screen and (min-width: $sm-screen) {
      display: block;
      max-height: 100%;
      padding: $margin-md $margin-md $margin-md ;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $radius;
      }
    }
  }
}